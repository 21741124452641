import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import image from '../images/home-entertainment.jpg'

const Page = () => (
  <Layout>
    <SEO title="Home Entertainment" />
    <img alt="home-entertainment" src={image}></img>
  </Layout>
)

export default Page
